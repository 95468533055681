import React, { useEffect, useState,useRef } from 'react'
import { postEnquiryDetails } from '../../actions/enquiryActions'
import MetaData from '../Layouts/MetaData'
import { useDispatch, useSelector } from 'react-redux'
import {  toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import NumberInput from '../Layouts/NumberInput';
import { clearEnquiryDeleted ,clearEnquiry} from '../../slices/enquirySlice';

const Enquiry = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        message: ""
    });
// console.log("formdata",formData)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    sessionStorage.setItem('redirectPath', location.pathname);
    const { loading, error,isSubmitted} = useSelector(state => state.enquiryState);

    // const hasShownToast = useRef(false);

    const submitHandler = async(e) => {
        e.preventDefault();
        await dispatch(postEnquiryDetails(formData));
        // setFormData("");
        console.log(formData)
    };

    const handleChange = (e) => {
        // e.preventDefault();
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(()=>{
        if(isSubmitted  ){
            toast('Enquiry Submitted successfully',{
                type:'success',
                position:"bottom-center",
                onOpen: () => dispatch(clearEnquiry())
              })
             

            navigate('/')
            
        } if(error){
            toast(error, {
                position: "bottom-center",
                type: 'error',
                onOpen: () => dispatch(clearEnquiry())
            })
            // hasShownToast.current = true;
        }
    },[isSubmitted,error])
   

    return (
        <div>
            <MetaData title={`Enquiry`} />
            <div className="products_heading">Enquiry</div>

            <div className="row wrapper mt-0">
                <div className="col-10 col-lg-5">
                    <form onSubmit={submitHandler} className="border" style={{ marginTop: "0px" }}>
                        <div className="form-group">
                            <label htmlFor="name_field">Name <span style={{color:'red'}}>*</span></label>
                            <input
                                type="text"
                                id="name_field"
                                name="name"
                                className="form-control"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email_field">Email <span style={{color:'red'}}>*</span></label>
                            <input
                                type="email"
                                id="email_field"
                                name="email"
                                className="form-control"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phone_field">Mobile <span style={{color:'red'}}>*</span></label>
                            <NumberInput
                                id="mobile_field"
                                name="mobile"
                                className="no-arrow-input form-control"
                                value={formData.mobile}
                                onChange={handleChange}  
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="message_field">Message <span style={{color:'red'}}>*</span></label>
                            <textarea
                                id="message_field"
                                name="message"
                                className="form-control"
                                style={{ height: "20vh" }}
                                value={formData.message}
                                onChange={handleChange}
                            />
                        </div>

                        <button
                            id="submit_button"
                            type="submit"
                            className="btn btn-block py-3"
                            disabled={loading}
                        >
                            Submit Request
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Enquiry;
